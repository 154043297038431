html {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Inter", sans-serif;
  overflow-y: scroll;
  margin: 0px 0px 200px 0px;
  background-color: #f1f1f1;
}

button {
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  background-color: transparent;
  cursor: pointer;
  font-family: "Inter", sans-serif;
}

h1 {
  margin-top: 0px;
  margin-bottom: 0px;
}

a {
  text-decoration: none;
  color: #000000;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

hr {
  border-top: 1px solid #5d99b9;
  border-bottom: transparent;
  border-right: transparent;
  border-left: transparent;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::placeholder {
  color: #cecece;
}

:-ms-input-placeholder {
  color: #cecece;
}

::-ms-input-placeholder {
  color: #cecece;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  overflow: hidden;
  box-sizing: border-box;
  background-color: transparent;
  margin: auto;
  text-align: left;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

input {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 10px;
  display: inline-block;
  border: 1px solid #aeb0b1ff;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  font-family: "Inter", sans-serif;
  height: 40px;
}

input[type="range"] {
  padding: 0 0;
}

textarea {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 10px;
  display: inline-block;
  border: 1px solid #aeb0b1ff;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  font-family: "Inter", sans-serif;
}

select {
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 10px;
  display: inline-block;
  border: 1px solid #aeb0b1ff;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  height: 40px;
  font-family: "Inter", sans-serif;
}

.radio_button {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 2rem;
  border: 3px solid #aeb0b1ff;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
}

.multi_check {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 0.5rem;
  border: 3px solid #aeb0b1ff;
  margin-right: 10px;
  font-size: 17px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
}

.main_container {
  width: 450px;
  max-width: calc((100vw - 40px));
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}

#icon_back {
  position: fixed;
  top: 30px;
  left: auto;
  cursor: pointer;
}

.nav_bottom {
  z-index: 99;
  position: fixed;
  bottom: 0px;
  width: calc((100% - 40px));
  height: 100px;
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 13px;
  color: #444444;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
  height: 50px;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-top: 5px;
  border-radius: 2rem;
}

#active_strong {
  color: #fff;
  background: #1799dd;
  border: 3px solid #1799dd;
}

#active {
  color: #000000;
  background: #c0e4ffff;
}

#not_active {
  color: #787878;
  background: transparent;
}

.fileContainer {
  cursor: pointer;
}

.fileContainer [type="file"] {
  filter: alpha(opacity=0);
  opacity: 0;
}

.input_header {
  color: #515151;
  margin-top: 18px;
  margin-bottom: 5px;
  font-weight: bold;
}

.input_note {
  color: #7d7d7d;
  margin-bottom: 10px;
}

/* Nav bottom */
.bottom_bar {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -2px 5px 5px rgba(0, 0, 0, 0.1);
}

.bottom_bar_grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.bottom_bar_item {
  display: flex;
  justify-content: center;
  align-items: center;
}

#home_icon {
  width: 22px;
  cursor: pointer;
}

.notif_user_name {
  display: inline-block;
  color: #1e5e96ff;
  font-weight: bold;
}

.notif_layer {
  display: inline-block;
  color: #1e5e96ff;
  font-weight: bold;
}

.notif_item {
  display: inline-block;
}

.title_big {
  font-size: 20px;
  color: #1e5e96ff;
  margin-bottom: 10px;
}

/* centering */
.center_vertical {
  display: flex;
  align-items: center;
  height: 100%;
}

.left_horizontal {
  justify-content: flex-start;
}

/* chat */
.chat_box {
  background-color: #e0eafc;
  background-image: linear-gradient(to bottom, #e0eafc, #cfdef3);
  overflow-y: scroll;
}

.chat_main {
  position: relative;
}

.chat_title {
  position: fixed;
  height: 70px;
  width: 100%;
  top: 0px;
  padding-left: 10px;
  background-color: rgb(216, 216, 216);
  z-index: 6;
}

.chat_title_text {
  display: inline-block;
  margin-left: 10px;
}

.chat_conversation_box {
  position: fixed;
  top: 70px;
  bottom: 180px;
  background-color: #e0eafc;
  background-image: linear-gradient(to bottom, #e0eafc, #cfdef3);
  width: calc((100vw - 20px));
  overflow-y: scroll;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.chat_writing {
  padding: 10px;
  width: calc((100% - 20px));
  height: 150px;
  background-color: #ffffff;
  display: inline-block;
  position: fixed;
  bottom: 0px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.chat_bubble {
  clear: both;
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: auto;
  padding: 3px;
  background: white;
  border-radius: 7px;
  margin: 5px 2px;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.chat_right {
  text-align: right;
}

.chat_left {
  text-align: left;
}

.chat_user {
  background-color: #62cdf2ff;
  background-image: linear-gradient(to right, #62cdf2ff, rgb(0, 191, 255));
  color: #ffffff;
}

.chat_member {
  background-color: #ffffff;
}

.chat_user_color {
  color: #ffffff;
}

.chat_other_color {
  color: #2bc0e4;
}

.chat_name {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 3px;
}

.chat_text {
  font-size: 13px;
}

/* kumpulan button class */
.buttonClose {
  border-radius: 2rem;
  border: none;
  background: #efefef;
  color: black;
  padding: 3px 10px 3px 10px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}

.button_tight {
  border-radius: 2rem;
  border: none;
  background: #efefef;
  color: black;
  padding: 3px 10px 3px 10px;
  text-align: center;
  display: inline-block;
  font-size: 11px;
  cursor: pointer;
  font-weight: 480;
}

.button_login {
  border-radius: 0.6rem;
  border: none;
  background: #62cdf2ff;
  color: white;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 15px 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0 0 4px #b9b9b913;
  -webkit-box-shadow: 0 0 4px #b9b9b913;
  -moz-box-shadow: 0 0 4px #b9b9b913;
}

.button_login:hover {
  background-color: #1e5e96ff;
}

.buttonShadowSmall {
  text-align: center;
  display: inline-block;
  border-radius: 7px 7px 7px 7px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  color: rgb(86, 86, 86);
  box-shadow: 0 0 4px #b9b9b913;
  -webkit-box-shadow: 0 0 4px #b9b9b913;
  -moz-box-shadow: 0 0 4px #b9b9b913;
  padding: 4px 12px 4px 12px;
  margin-bottom: 5px;
  font-size: 12px;
  cursor: pointer;
}

.button_circle {
  display: inline-block;
  position: relative;
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 600;
}

.button_circle_inside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button_register {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
}

.button_account {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.events_static {
  color: #ffffff;
  background-color: #0575e6;
  background-image: linear-gradient(to left, #00f260, #0575e6);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 4px #b9b9b913;
  -webkit-box-shadow: 0 0 4px #b9b9b913;
  -moz-box-shadow: 0 0 4px #b9b9b913;

  padding: 5px 20px 5px 20px;
  text-align: center;
  display: inline-block;

  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;

  margin-top: 15px;
}

.events_static:hover {
  color: #ffffff;
}

/* kumpulan button id */

#blue_outline {
  background-color: rgb(225, 228, 255);
  border: 1px solid #2649ef;
}

#status_active {
  background-color: #1e5e96ff;
  border: 1px solid #1e5e96ff;
  color: #ffffff;
}

#status_non_active {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  color: rgb(86, 86, 86);
}

#deleteBright {
  background-color: #ed1c24;
  color: white;
}

#red_text {
  color: #ed1c24;
}

#red_outline {
  background-color: rgb(255, 225, 225);
  border: 1px solid #ed1c24;
  border-radius: 17px;
}

#green_text {
  color: #51ca00;
}

#red {
  background-color: #ed1c24;
  color: white;
}

#pink {
  background-color: #ffb7a7;
  color: #000000;
}

#editBright {
  background-color: rgb(54, 116, 171);
  color: white;
}

#purple {
  background-color: rgb(111, 0, 148);
  color: white;
}

#dark_blue {
  background-color: #244aa1;
  color: white;
}

#bright_blue {
  background-color: #b4e1fd;
  color: #2893d7;
}

#black {
  background-color: #000000;
  border: 1px solid #000;
  color: white;
}

#grey {
  background-color: #52606e;
  color: white;
}

#white_background {
  background-color: white;
  border: 1px solid #9eb3c0;
}

#white_only {
  background-color: white;
  color: #52606e;
}

#white {
  background-color: white;
  color: #52606e;
  border: 1px solid #9eb3c0;
}

#white_text {
  color: #ffffff;
}

#grey_light {
  background-color: #778b9f;
  color: white;
}

#blue {
  background-color: #0ca5eb;
  color: white;
}

#list_active {
  background-color: rgb(255, 255, 255);
  color: #0ca5ebff;
  border-bottom: 2px solid rgb(130, 200, 232);
}

#list_non_active {
  background-color: rgb(255, 255, 255);
  color: #60778e;
  border-bottom: 2px solid #d3dbe1;
}

#bright_clear {
  background-color: rgb(244, 244, 244);
  color: rgb(0, 0, 0);
}

#delete {
  background: #ffe9e9;
  color: #9b2020;
}

#deleteBold {
  background: #9b2020;
  color: #ffe9e9;
  padding: 0px 10px 0px 10px;
  font-size: 10px;
}

#edit {
  background: #e9f9ff;
  color: #20669b;
}

#selected {
  background-color: #0575e6;
  background-image: linear-gradient(to right, #00de59, #0166cb);
  color: #ffffff;
}

#comfort {
  background: #e9f9ff;
  color: #20669b;
  margin-top: 10px;
  margin-right: 10px;
}

#editBright {
  background-color: rgb(54, 116, 171);
  color: white;
}

/* kumpulan container class */
.container_select_input {
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  padding: 15px;
  margin-bottom: 20px;
}

.container_select_content {
  margin-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  height: 30px;
}

.welcomeBig {
  font-weight: bolder;
  color: #1e5e96ff;
  text-align: left;
  font-size: 30px;
}

.generic_container {
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 15px 15px 15px 15px;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  color: #5e5e5e;
  cursor: pointer;
}

.specialItemUser {
  width: 300px;
  height: 600px;
  min-width: 300px;
  max-width: 80vw;
  max-height: 100vh;
  background-color: #0575e6;
  background-image: linear-gradient(to bottom, #00f260, #0575e6);
  border: 1px solid #d8d8d8;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 4px #b9b9b913;
  -webkit-box-shadow: 0 0 4px #b9b9b913;
  -moz-box-shadow: 0 0 4px #b9b9b913;
  padding: 15px 15px 15px 15px;
  margin: 10px 10px 10px 10px;
  text-align: left;
  text-decoration: none;

  color: #5e5e5e;
}

.specialItemGroup {
  width: 300px;
  height: 600px;
  min-width: 300px;
  max-width: 80vw;
  max-height: 100vh;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  box-shadow: 0 0 4px #b9b9b913;
  -webkit-box-shadow: 0 0 4px #b9b9b913;
  -moz-box-shadow: 0 0 4px #b9b9b913;
  padding: 15px 15px 15px 15px;
  margin: 10px 10px 10px 10px;
  text-align: left;
  text-decoration: none;

  color: #5e5e5e;
}

.carousellGroup {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.documentContainer {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  max-width: 80vw;
  overflow-x: hidden;
}

.landing-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0px;
}

.landing-login {
  margin-left: -80px;
  width: 28vw;
}

.landing-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding-top: 0px;
}

.input_icon {
  display: grid;
  grid-template-columns: auto 30px;
  grid-gap: 5px;
  align-items: center;
}

/* kumpulan class header text */
.gentle_header {
  font-size: 17px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.gentle_text {
  font-size: 13px;
  color: #5e5e5e;
}

/* kumpulan_container */
.container_compact_no_margin {
  min-width: 195px;
  min-height: 100px;
  background-color: #0ca5ebff;
  color: #ffffff;
  border-radius: 15px;
  padding: 20px;
}

.two_container_equal {
  display: grid;
  grid-template-columns: calc((50% - 5px)) calc((50% - 5px));
  grid-gap: 10px;
}

.two_container_dashboard {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-gap: 20px;
}

.three_container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
}

.dashboard_box_container {
  display: grid;
  grid-template-columns: 60px auto auto;
  grid-gap: 10px;
}

.parent_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_light {
  background-color: rgb(225, 228, 255);
  border: 1px solid #2649ef;
  border-radius: 17px;
  font-size: 13px;
  font-weight: 400;
  padding: 10px;
}

.container_white {
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  font-size: 13px;
  font-weight: 400;
  padding: 10px;
}

.container_glossy {
  background-image: linear-gradient(to right, #00c480ff, #009ce3ff);
  color: #ffffff;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 400;
  padding: 10px;
}

#container_normal {
  margin-bottom: 20px;
  margin-top: 10px;
}

.upload_container {
  cursor: pointer;
  width: 100px;
  height: 100px;
  border: 2px dashed #c9c9c9;
  border-radius: 7px;
}

.upload_pp_container {
  margin-top: 5px;
  cursor: pointer;
  width: 60px;
  height: 20px;
  border: 2px dashed #c9c9c9;
  border-radius: 7px;
}

.vertical_center {
  display: inline-grid;
}

.vertical_horizontal_center_child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical_center_child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.phone_number {
  display: grid;
  grid-template-columns: 100px auto;
}

.check_container {
  display: grid;
  grid-template-columns: 30px auto;
}

.two_container_icon_right {
  display: grid;
  grid-template-columns: auto 50px;
}

.read_content {
  z-index: 17;
  position: fixed;
  overflow-y: auto;
  bottom: -100vh;
  width: 100%;
  height: 95vh;
  background-color: #f3f6f8ff;
  color: #52606eff;
  border-radius: 30px 30px 0px 0px;
  box-shadow: 0 0 10px rgba(67, 67, 67, 0.859);
  transition: bottom 0.2s ease-in-out;
}

/* kumpulan_button*/

.button_pill {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  border-radius: 2rem;
  padding: 3px 7px 3px 7px;
  cursor: pointer;
}

.button_standard {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  border-radius: 7px;
  padding: 7px 17px 7px 17px;
  cursor: pointer;
  background-color: #0ca5ebff;
  color: #fff;
}

.button_very_small {
  text-align: center;
  display: inline-block;
  border-radius: 5px 5px 5px 5px;
  background-color: #ffffff;
  color: rgb(86, 86, 86);
  padding: 3px 6px 3px 6px;
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
}

.button_tight {
  padding: 4px 7px 4px 7px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  background-color: #0ca5ebff;
  color: #ffffff;
}

.button_lingkaran {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #0ca5eb;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}

.button_block_tight {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #2196f3;
  color: #ffffff;
}

/* kumpulan_warna_button */
#white_normal {
  background-color: #ffffff;
  color: #6e6e6e;
  box-shadow: 0 0 4px #b9b9b913;
  -webkit-box-shadow: 0 0 4px #b9b9b913;
  -moz-box-shadow: 0 0 4px #b9b9b913;
}

#white {
  background-color: #ffffff;
  color: #2196f3;
}

#purple {
  background-color: rgb(111, 0, 148);
  color: white;
}

#green {
  background-color: rgb(93, 191, 80);
  color: white;
}

#orange {
  background-color: #febf4c;
  color: #000;
}

#dark_blue {
  background-color: #244aa1;
  color: white;
}

#bright_blue {
  background-color: #b4e1fd;
  color: #2893d7;
}

#black {
  background-color: #000000;
  color: white;
}

#grey {
  background-color: #52606e;
  color: white;
}

#grey_light {
  background-color: #778b9f;
  color: white;
}

#almost_white {
  background-color: #f1f1f1;
  color: white;
}

#blue_disable {
  background-color: #cde5f2;
  color: #778b9f;
}

#blue {
  background-color: #0ca5ebff;
  color: white;
}

#white_grey {
  border: 1px solid rgb(219, 219, 219);
  background-color: #fff;
  color: #393939;
}

#white_blue {
  border: 1px solid #0ca5ebff;
  background-color: #fff;
  color: #393939;
}

#outline_black {
  border: 2px solid #02044a;
  background-color: #ffffff;
  color: #02044a;
}

#black_absolute {
  border: 2px solid #02044a;
  background-color: #02044a;
  color: #ffffff;
}

/* kumpulan text */

.text_bold {
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 470;
  line-height: 110%;
  letter-spacing: -0.3px;
  color: #000;
}

.text_header {
  margin-bottom: 0px;
  font-size: 35px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.3px;
}

.text_inferior {
  font-size: 12px;
  font-weight: 500;
  color: #294959;
}

.text_medium {
  font-size: 15px;
}

.text_small {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}

.text_big {
  font-size: 27px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.6px;
}

.text_thin {
  font-size: 15px;
  margin-bottom: 5px;
  color: #52606e;
}

/*kumpulan_badge*/

.badge {
  font-size: 11px;
  font-weight: 600;
  display: inline-block;
  background-color: rgb(225, 225, 225);
  padding: 3px 9px 3px 9px;
  border-radius: 1em;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #000000;
}

.badge_normal {
  font-size: 12px;
  display: inline-block;
  background-color: rgb(225, 225, 225);
  padding: 4px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #000000;
}

.badge_bold {
  font-size: 12px;
  font-weight: bolder;
  display: inline-block;
  background-color: #1e5e96ff;
  color: #ffffff;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* kumpulan_slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider_check {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.slider_check:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

input:checked + .slider_check {
  background-color: #2196f3;
}

input:focus + .slider_check {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider_check:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider_check.round {
  border-radius: 17px;
}

.slider_check.round:before {
  border-radius: 50%;
}

.button {
  padding: 4px 7px 4px 7px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  background-color: #0ca5eb;
  color: #ffffff;
}

.button_tab {
  min-width: 70px;
  padding: 5px 13px 5px 13px;
  text-align: center;
  display: inline-block;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  background-color: #0ca5eb;
  color: #ffffff;
  white-space: nowrap;
}

.button_small {
  padding: 3px 8px 3px 8px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  background-color: #0ca5eb;
  color: #ffffff;
}

.margin_bottom {
  margin-bottom: 7px;
}

.margin_right {
  margin-right: 2px;
}

.margin_bottom_big {
  margin-bottom: 15px;
}

.table_lite {
  font-size: 12px;
  text-align: left;
  color: black;
  padding: 5px;
  border-collapse: collapse;
  height: auto;
  overflow-y: auto;
  min-width: 100%;
  overflow-x: auto;
  background-color: #fff;
  border: 1px solid #000000;
}

.table_2 {
  width: 100%;
  table-layout: fixed;
}

.table_lite tbody tr td {
  border: 1px solid #000000;
}

.table_lite thead {
  background-color: #dedede;
  border: 1px solid #000000;
  z-index: 1000;
}

.table_lite th {
  border: 1px solid #000000;
  font-weight: bold;
  text-align: left;
  padding: 5px;
  background-color: #dedede;
  color: #000000;
  vertical-align: top;
  /* Freeze */
  position: sticky;
  top: 0;
}

.table_lite td {
  text-align: left;
  padding: 5px;
  vertical-align: top;
  /* min-width: 150px; */
  border: 1px solid #000000;
}

.table_lite tr:nth-child(even) {
  background-color: #f0f0f0;
  vertical-align: top;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.bottom_fix {
  position: absolute;
  bottom: 0px;
}

.margin_auto {
  margin: auto;
}

.side {
  display: inline-block;
  vertical-align: top;
}

.margin_bottom {
  margin-bottom: 10px;
}

.margin_bottom_x {
  margin-bottom: 30px;
}

.margin_top {
  margin-top: 10px;
}

.margin_top_minor {
  margin-top: 3px;
}

.margin_right {
  margin-right: 10px;
}

.margin {
  margin: 5px;
}

.text_right {
  text-align: right;
}

.button_dev {
  padding: 7px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 8px;
  font-weight: 600;
  background-color: #0ca5eb;
  color: #ffffff;
}

.status_box {
  width: 100%;
  background-color: #009de3cc;
  background-image: linear-gradient(to right, #009de3cc, #2bbeffcc);
  color: #ffffff;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 3px;
  text-align: center;
  z-index: 7;
  margin-bottom: 10px;
}

.button_account {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.button_register {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
}

.inline {
  display: inline-block;
}

#checklist_active {
  background-color: #269d42ff;
  color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: 2px solid #269d42ff;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

#checklist_not {
  background-color: #fff;
  color: #000;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: 2px solid rgb(113, 113, 113);
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

#checklist_active::before {
  content: "✔";
}

#checklist_not::before {
  content: "-";
}

.grid_item {
  vertical-align: middle;
  display: inline-block;
}

.button_block {
  width: calc((100% - 10px));
  padding: 7px;
  text-align: center;
  display: block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  box-sizing: border-box;
}

.arrow_up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid #02044a;
}

.arrow_down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #02044a;
}

.arrow_right {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #02044a;
}

.arrow_left {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 3px solid #02044a;
}

.max_modal {
  min-height: 80vh;
}

/*  ----- POP UP ----- */
.newPopup {
  width: 170px;
  height: 100%;
  max-height: 200px;
  padding-left: 5px;
  overflow-y: auto;
  overflow-x: auto;
}

.valueNewPopup {
  max-width: 150px;
  width: 100%;
  word-break: break-word;
}

.valueNewPopup p {
  color: #0ca5eb;
  font-size: 0.7rem;
  /* margin-bottom: 2px; */
  cursor: default;
}

.close_button_popup {
  width: 15px;
  height: 15px;
  background-color: #ff0000;
  position: absolute;
  top: 3px;
  right: 3px;
  color: #fff;
  font-size: 0.7rem;
  padding: 2px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.valueNewPopup h3,
.valueNewPopup a {
  font-size: 0.9rem;
  font-weight: 500;
  margin: -7px 0 5px 0;
}

.valueNewPopup a {
  color: #0ca5eb;
}

.fields_general {
  transition: max-height 0.3s linear;
  overflow: auto;
}

#item_open {
  max-height: 100%;
}

#item_close {
  max-height: 0px;
}

.search_location {
  position: absolute;
  width: 40%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 10px;
}

.lat_long_map {
  position: absolute;
  width: 45%;
  max-width: 300px;
  bottom: 3px;
  left: 10px;
}

.lat_long_map input {
  margin-left: 5px;
  padding: 0;
  width: 170px;
  text-align: right;
  height: 20px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.sub_lat_long_map {
  display: grid;
  grid-template-columns: 30px auto;
  max-width: 300px;
  bottom: 10px;
  left: 10px;
}

.search_location input {
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 3px 5px;
  margin-bottom: 0;
}

.list_location {
  width: 98%;
  background-color: #fff;
  max-height: 270px;
  overflow: auto;
}

.list_location h1 {
  padding: 5px 10px;
  font-size: 15px;
}

.list_location p {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.list_location p:hover {
  background-color: #0ca5eb;
}

.button_full {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  background-color: #fff;
  border: 1px solid #000;
  padding: 5px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.button_full img {
  width: 100%;
  height: 100%;
}

.button_full_map {
  position: fixed;
  z-index: 90;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}

.icon_full_map {
  position: absolute;
  z-index: 90;
  width: 50px;
  height: 50px;
  left: auto;
  top: 47.5%;
  left: 49.95%;
  transform: translate(-47.7%, -50%);
}

.icon_full_map img {
  width: 100%;
  height: 100%;
}

.button_action_full_map {
  margin-left: 5px;
  background-color: #fff;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  padding: 0;
}

.button_action_full_map img {
  width: 100%;
  height: 100%;
}

.info_map {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/*TABLE FIX*/

.table {
  font-size: 12px;
  position: relative;
  border-collapse: collapse;
  background-color: #ffffff;
}

.table td,
th {
  padding: 0.25em;
  vertical-align: top;
}

.table thead th tr {
  border: 1px solid #000000;
}

.table td {
  border: 1px solid #729fa1;
}

.table thead th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  background: #dedede;
  color: #000000;
  border: 1px solid #000000;
  /* vertical-align: bottom; */
}

.table thead th:first-child {
  left: 0;
  z-index: 1;
}

.table tbody th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  background: #dedede;
  border-right: 1px solid #000000;
}

.table tr:nth-child(even) {
  background: #ededed;
}

.border {
  border: 1px solid;
}

.container_checkbox {
  display: grid;
  grid-template-columns: 50% 50%;
}

.checkmark {
  width: 15px;
  height: 15px;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.justify_between {
  justify-content: space-between;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.break_word {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.button_save_offline {
  height: 25px;
  padding: 3px 8px 3px 8px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  background: #0ca5eb;
  color: #ffffff;
}

.button_save_offline:hover {
  transform: scale(1.1);
}

#button_save_offline_active {
  cursor: default;
  background: #7cc735ff;
  transform: scale(1);
}

.center_perfect {
  display: flex;
  justify-content: center;
  align-items: center;
}
